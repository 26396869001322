// Custom Profile component; not part of @shadcdn/ui
import { useState, useRef } from "react"
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Separator } from "@/components/ui/separator"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { User, Camera } from "lucide-react";
import { toast } from "sonner"

const FileInput = ({ onChange, ...props }) => {
    const ref = useRef(null)
    return (
        <div>
            <input
                type="file"
                className="hidden"
                ref={ref}
                onChange={(e) => {
                    const file = e.target.files?.[0]
                    if (file) {
                        onChange(file)
                    }
                }}
                {...props}
                accept="image/*"
            />
            <Button
                type="button"
                variant="outline"
                size="icon"
                className="rounded-full absolute bottom-0 right-0 bg-background"
                onClick={() => ref.current?.click()}
            >
                <Camera className="h-4 w-4" />
            </Button>
        </div>
    )
}

export default function Profile({ store = {} }) {
    const [open, setOpen] = useState(false)
    const [profileImage, setProfileImage] = useState(store.Auth?.profile ? (store.Auth?.profile?.picture ?? `https://robohash.org/${store.Auth?.profile?.sub}.png`) : null)
    const [profile, setProfile] = useState({
        name: store.Auth?.profile?.name ?? "",
        username: store.Auth?.profile?.username ?? "",
        email: store.Auth?.profile?.email ?? "",
    })

    const handleFileChange = (file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            setProfileImage(e.target.result)
        }
        reader.readAsDataURL(file)
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Avatar className="cursor-pointer">
                    <AvatarImage src={profileImage} alt="User avatar" />
                </Avatar>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Edit Profile</DialogTitle>
                    <DialogDescription>
                        Make changes to your profile here. Click save when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <Avatar className="h-24 w-24">
                                <AvatarImage src={profileImage} alt="Profile picture" />
                                <AvatarFallback>??</AvatarFallback>
                            </Avatar>
                            <FileInput
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input defaultValue={profile.name} id="name" className="col-span-3" />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username">
                            Username
                        </Label>
                        <Input id="username" defaultValue={profile.username} className="col-span-3" />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="email">
                            Email
                        </Label>
                        <Input disabled id="email" defaultValue={profile.email} className="col-span-3" />
                    </div>
                </div>
                <DialogFooter>
                    <Button type="submit" onClick={() => {
                        toast.error("Not yet implemented!")
                        setOpen(false)
                    }}>
                        Save changes
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}