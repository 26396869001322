import { set } from "mobx";
import { useEffect, useRef, useState } from "react";
export function useEventSource(url, withCredentials, ESClass = EventSource) {
    const source = useRef(null);
    const [status, setStatus] = useState("init");

    useEffect(() => {
        if (url) {
            setStatus("connecting");
            const es = new ESClass(url, { withCredentials });
            source.current = es;
            es.addEventListener("open", () => setStatus("open"));
            es.addEventListener("error", () => setStatus("error"));
            es.addEventListener("close", () => setStatus("closed"));
            return () => {
                source.current = null;
                es.close();
            };
        }
        setStatus("closed");
    }, [url, withCredentials, ESClass]);

    return [source.current, status];
}

export function useEventSourceListener(source, types, dependencies = []) {
    useEffect(() => {
        if (source) {
            types.forEach(([type, listener]) => source.addEventListener(type, listener));
            return () => types.forEach(([type, listener]) => source.removeEventListener(type, listener));
        }
    }, [source, ...dependencies]);
}
