import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Moon, Sun, Menu, Github, HomeIcon, User, Mail, Settings, HelpCircle, ChevronLeft, ChevronRight, Server, ServerCrash } from "lucide-react";
import { getNavLink } from "./Nav";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useTheme } from "@/lib/providers/theme";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { toast } from "sonner";

function SidenavItems(page = "home", {
    store,
    toggles = {}, // Functions to call 
}) {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    return [
        {
            icon: store.EventSourceStatus == "open" ? Server : ServerCrash,
            label: `Sync ${store.EventSourceStatus == "open" ? "Connected" : "Disconnected"}`,
            onClick: () => toast.warning("Not yet implemented!"),
        },
        { break: true },
        ...(() => {
            switch (String(page).toLowerCase()) {
                case "home":
                    return [
                        { icon: HomeIcon, label: "Home", onClick: () => navigate(getNavLink("home").path) },
                        { icon: Mail, label: "Messages" },
                        { icon: Settings, label: "Settings" },
                        { icon: HelpCircle, label: "Help" },
                        { break: true },
                    ]
                case "support":
                    return [
                        { icon: HomeIcon, label: "Home", onClick: () => navigate(getNavLink("home").path) },
                        { icon: Github, label: "GitHub", onClick: () => window.open("https://github.com/openpush", "_blank", "noreferrer") },
                        { break: true },
                    ]
                default:
                    return []
            }
        })(),
        {
            icon: theme === "dark" ? Sun : Moon,
            label: `${theme === "dark" ? "Light" : "Dark"} Mode`,
            onClick: () => runInAction(() => toggleTheme())
        },
    ];
}

const Sidenav = observer(({ options, store, toggles }) => {
    let {
        scrolled = false,
        sidePanelOpen = true,
    } = options || {};

    return (
        <>
            <aside className={`w-10 left-0 h-content fixed bg-gray-200 dark:bg-gray-800 z-10 bottom-0 transition-transform duration-300 ${scrolled ? "top-20" : "top-28"} ${sidePanelOpen ? "translate-x-0" : "-translate-x-12"}`}>
                <nav className="flex flex-col items-center py-4">
                    {
                        SidenavItems(store.Active, {
                            store,
                            toggles,
                        }).map((item, index) => (
                            <span
                                key={index}
                            >
                                {
                                    item.break ?
                                        <hr className="w-10 border-gray-300 dark:border-gray-600 pb-4" />
                                        :

                                        <TooltipProvider delayDuration={100}>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        key={index}
                                                        variant="ghost"
                                                        size="icon"
                                                        className="mb-4 dark:hover:bg-gray-700"
                                                        aria-label={item.label}
                                                        onClick={() => item.onClick?.()}
                                                    >
                                                        <item.icon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent side="right">
                                                    <p>{item.label}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                }
                            </span>
                        ))
                    }
                </nav>
            </aside>
        </>
    )
});

export {
    Sidenav,
    SidenavItems,
}