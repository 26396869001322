import { createBrowserRouter, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Component as Error404 } from "./components/pages/codes/404";
import Home from "./components/pages/Home";
import { SessionStore } from "@/lib/stores";
import { useHandleSignInCallback, useLogto } from '@logto/react';
import { useEffect, useState } from "react";
import Component from "./components/pages/Home";

export default createBrowserRouter([
    {
        path: "/",
        element: <Layout store={SessionStore} />,
        id: "root",
        children: [
            {
                index: true,
                element: <Home />,
                id: "home",
            },
            {
                path: "notifications",
                async lazy() {
                    let Notifications = (await import("./components/pages/Notifications")).Component
                    return { Component: () => <Notifications store={SessionStore} /> };
                },
                id: "notifications",
            },
            {
                path: "settings",
                async lazy() {
                    let Settings = (await import("./components/pages/Settings")).Component
                    return { Component: () => <Settings store={SessionStore} /> };
                },
                id: "settings",
            },
            {
                path: "callback",
                Component: () => {
                    const navigate = useNavigate();
                    const { isLoading } = useHandleSignInCallback(() => { navigate('/notifications') });
                    if (isLoading) {
                        return (<div>Sign in successful, redirecting...</div>)
                    }
                    return (<h3>Invalid callback request.</h3>)
                },
                id: "callback",
            },
            {
                path: "sign-in",
                Component: () => {
                    const { isAuthenticated, isLoading, signIn } = useLogto(),
                        navigate = useNavigate();
                    useEffect(() => {
                        if (!isAuthenticated && !isLoading) {
                            void signIn(`${window.location.origin}/callback`);
                        } else if (isAuthenticated) {
                            void navigate('/');
                        }
                    }, [isAuthenticated, isLoading, signIn, navigate]);
                    return isAuthenticated ? <p>Already authenticated</p> : <p>Not authenticated</p>;
                }
            },
            {
                path: "*",
                element: <Error404 />,
                id: "404",
            },
        ],
    },
], {
    future: {
        v7_normalizeFormMethod: true,
    }
});