export function Component() {
    return (
        <>
            <p className="mt-3 mb-3 dark:text-gray-300">
                Sorry, this page doesn&apos;t currently exist.
            </p>
            <p className="mb-3 dark:text-gray-300">
                Please go back to the <a href="/" className="text-primary hover:underline">homepage</a>.
            </p>
        </>
    )
}