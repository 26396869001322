import { makeObservable, observable, action, computed, set } from "mobx";
import WordHash from "wordhash";

let wordHash = WordHash();

export class GroupStore extends Object {
    value = {};

    constructor(groups = []) {
        super();
        makeObservable(this, {
            value: observable,
            add: action,
            remove: action,
            ids: computed,
            values: computed,
            entries: computed,
            empty: computed
        }).add(groups);
    }

    // Add groups(s) to the store
    add(groups) {
        set(this.value, Object.fromEntries((Array.isArray(groups) ? groups : [groups]).map(i => {
            return (i instanceof Device ? i : new Device(i)).kv;
        })))
    }

    // Remove group(s) from the store
    remove(groups) {
        (Array.isArray(groups) ? groups : [groups]).forEach(i => {
            let item = i instanceof Group ? i : new Group(i);
            if (this.value.hasOwnProperty(item.id)) {
                if (item.id in this.valu) {
                    delete this.value[item.id];
                }
            }
        });
    }

    get ids() {
        return Object.keys(this.value)
    }

    get values() {
        return Object.values(this.value)
    }

    get entries() {
        return Object.entries(this.value)
    }

    get empty() {
        return Object.keys(this.value).length <= 0
    }
}

export class Group extends Object {
    value = {};

    constructor(content = {}) {
        super();
        makeObservable(this, {
            value: observable,
            id: computed,
            devices: computed,
            created_at: computed,
            kv: computed,
            name: computed,
        })
        Object.assign(this.value, {
            ...(content || {}),
            hashed: wordHash.hash(content?.id),
        });
    }

    get id() {
        return this.value.id
    }

    get devices() {
        return this.value.devices
    }

    get created_at() {
        return this.value.created_at
    }

    get name() {
        return this.value.hashed
    }

    get kv() {
        return [this.id, this]
    }
}