import { UserScope } from '@logto/react';
import { observable } from 'mobx';

let defaults = {
    title: 'OpenPush',
    description: 'WebPush API',
    api: 'https://api.openpu.sh',
    base: 'https://openpu.sh',
    logo: 'https://cdn.openpu.sh/images/web/icon-192.png',
};

let Website = observable(defaults);
try {
    Website = {
        ...defaults,
        ...(JSON.parse(process.env.WEBSITE || '{}') || {}),
    }
} catch (err) {
    console.error(err)
}

let Sync = observable({
    enabled: false,
    service: 'https://api.openpu.sh',
});
try {
    Sync = {
        ...Sync,
        ...(JSON.parse(process.env.SYNC || '{}') || {}),
    }
} catch (err) {
    console.error(err)
}

let LogtoConfig = observable({
    scopes: [
        UserScope.Email,
        UserScope.Phone,
        UserScope.CustomData,
        UserScope.Identities,
        UserScope.Organizations,
        'read',
        'write'
    ],
});
try {
    LogtoConfig = {
        ...LogtoConfig,
        ...(JSON.parse(process.env.AUTH || '{}') || {}),
    };
    LogtoConfig.resources = [
        ...new Set([
            ...(LogtoConfig.resources || []),
        ])
    ];
} catch (err) {
    console.error(err)
}

let VapidKey;
try {
    VapidKey = process.env.VAPID;
} catch (err) {
    console.error(err)
}

export {
    Website,
    Sync,
    LogtoConfig,
    VapidKey,
}