import { makeObservable, observable, reaction } from 'mobx';
import { NotificationsStore } from './storage/notifications';
import { DeviceStore } from './storage/devices';
import { GroupStore } from './storage/groups';
import { Website } from './settings';

// Create a session store
export let SessionStore = observable({
    Auth: {
        isAuthenticated: false,
        token: null,
        profile: null,
    },
    Active: 'home',
    EventSourceStatus: "close",
});

// Create a notifications store
export let Notifications = observable(new NotificationsStore());

// Create a device store
export let Devices = observable(new DeviceStore());

// Create a groups store
export let Groups = observable(new GroupStore());

// Inject timer to dummy data
//injectTimer({ store: Notifications });

// Trigger initial data fetching
reaction(
    () => SessionStore.Auth.isAuthenticated,
    (authed) => {
        if (authed) {
            (async () => {
                [
                    ['GET', '/v1/notifications', 'notifications', Notifications],
                    ['GET', '/v1/devices', 'devices', Devices],
                 // ['GET', '/v1/groups', 'groups', Groups],
                ].map(async ([method, url, key, store, params = {}]) => {
                    let resp = await fetch(`${Website.api}${url}`, {
                        method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${SessionStore.Auth.token}`,
                        },
                        ...params,
                    })
                    if (resp.ok) store.add((await resp.json())?.[key] || []);
                })
            })()
        }
    }
)

export default SessionStore;
window.SessionStore = SessionStore;
