import { useState, useEffect } from "react";

export const useBrowserStore = (key, defaultValue, store = window.sessionStorage) => {
    const [value, setValue] = useState(() => {
        try {
            return JSON.parse(store.getItem(key)) ?? defaultValue;
        } catch { }
        return defaultValue
    });

    useEffect(() => store.setItem(key, JSON.stringify(value)), [key, value]);

    return [value, setValue];
};