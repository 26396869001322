import {
    Outlet,
    Link,
    useMatches,
    useNavigate,
} from "react-router-dom";
import { useState, useEffect } from 'react';
import { Moon, Sun, Menu, X, Github, Twitter, Linkedin, HomeIcon, User, Mail, Settings, HelpCircle, ChevronRight, ChevronLeft, PanelLeft, PanelLeftClose } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Nav, MobileNav } from '@/components/Nav';
import { Sidenav } from '@/components/SideNav';
import { observer } from 'mobx-react-lite';
import { observable, runInAction } from "mobx";
import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { useLogto } from '@logto/react';
import { Website, Sync } from '@/lib/settings';
import { Toaster } from "@/components/ui/sonner";
import { useEventSource, useEventSourceListener } from "@/lib/hooks/eventSource";
import { Notifications, Devices } from "@/lib/stores";
import { useBrowserStore } from "@/lib/hooks/browserStore";
import { toast } from "sonner";
import { set } from "mobx";
import { isTokenExpired } from "@/lib/utils";

const Layout = observer(({ store }) => {
    const matches = useMatches();
    const { isAuthenticated, getAccessToken, fetchUserInfo, signIn } = useLogto();
    const [scrolled, setScrolled] = useState(false)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [syncUrl, setSyncUrl] = useState(null);
    const [eventSource, eventSourceStatus] = useEventSource(syncUrl, false);
    const [sidePanelOpen, setSidePanelOpen] = useBrowserStore("side-panel-open", true, localStorage)
    const [syncKey, setSyncKey] = useBrowserStore("sync-key", null);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            let token, profile;
            if (isAuthenticated) {
                try {
                    token = await getAccessToken(Website.api);
                    profile = await fetchUserInfo();
                    if ([token, profile].some(val => !val)) {
                        return void signIn(`${ window.location.origin }/callback`);
                    }
                    if (token) {
                        if (isTokenExpired(syncKey)) {
                            (async () => {
                                await fetch(`${Sync.service}/sync/key`, {
                                    headers: { 'Authorization': `Bearer ${token}` }
                                }).then(async (response) => {
                                    if (response.ok) {
                                        try {
                                            return setSyncKey((await response.json()).key);
                                        } catch { }
                                    }
                                    return Promise.reject()
                                }).catch(() => setSyncKey(null))
                            })();
                        }
                    } else {
                        setSyncKey(null);
                    }
                } catch (e) {
                    console.trace(e)
                }
            }
            runInAction(async () => set(store, {
                Auth: { isAuthenticated, token, profile },
            }))
        })();
    }, [isAuthenticated, store, getAccessToken, fetchUserInfo, signIn, setSyncKey]);

    useEffect(() => {
        setSyncUrl(isAuthenticated && syncKey ? `${Sync.service}/sync/connect/${syncKey}` : null);
    }, [isAuthenticated, syncKey, setSyncUrl]);

    useEventSourceListener(
        eventSource,
        [
            ["notifications:insert", (event) => Notifications.add(JSON.parse(event.data))],
            ["notifications:delete", (event) => Notifications.remove(JSON.parse(event.data))],
            ["devices:insert", (event) => Devices.add(JSON.parse(event.data))],
            ["devices:delete", (event) => Devices.remove(JSON.parse(event.data))],
        ],
        [Notifications, Devices],
    );

    useEffect(() => {
        runInAction(() => set(store, { EventSourceStatus: eventSourceStatus }));
        if (eventSource) {
            switch (eventSourceStatus) {
                case "connecting":
                    toast.info("Connecting...", {
                        description: "Attempting to establish a connection to the Sync server.",
                    })
                    break;
                case "close":
                case "error":
                    toast.error("Disconnected!", {
                        description: "Your connection to the Sync server has been lost.",
                    })
                    break;
                case "open":
                    toast.success("Connected!", {
                        description: "Your connection to the Sync server has been established.",
                    })
            }
        }
    }, [eventSource, eventSourceStatus, toast])

    useEffect(() => {
        runInAction(() => set(store, { Active: matches[matches.length - 1].id }));
    }, [matches])

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled)
            }
        }
        document.addEventListener('scroll', handleScroll, { passive: true })
        return () => document.removeEventListener('scroll', handleScroll)
    }, [scrolled])

    const toggles = {
        mobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
        sidePanel: () => setSidePanelOpen(!sidePanelOpen)
    }

    return (
        <>
            <div className="dark:bg-gray-900 bg-gray-100 min-h-screen">
                {/* Header */}
                <header
                    className={`sticky top-0 left-0 right-0 z-50 ${scrolled
                        ? 'h-20 bg-white/70 dark:bg-gray-800/70 shadow backdrop-blur-md'
                        : 'h-28 bg-white dark:bg-gray-800'
                        }`}
                >
                    <div className="container mx-auto px-4 h-full flex items-center justify-between">
                        <div className="flex items-center justify-between">
                            <div className="flex w-6 h-12 mr-6 cursor-pointer justify-center items-center" onClick={() => setSidePanelOpen(!sidePanelOpen)}>
                                {
                                    sidePanelOpen ?
                                        <PanelLeftClose className="w-6 h-6" />
                                        :
                                        <PanelLeft className="w-6 h-6" />
                                }
                            </div>
                            <div className="flex w-12 h-12 mr-4">
                                {Website.logo ? <img src={Website.logo} alt="Site logo" /> : null}
                            </div>
                            <div className="flex flex-col">
                                <h1 className={`font-bold ${scrolled ? 'text-xl' : 'text-3xl'} dark:text-white`}>
                                    {Website.title || 'OpenPush'}
                                </h1>
                                <p className={`text-gray-600 dark:text-gray-400 ${scrolled ? 'text-xs' : 'text-sm'}`}>
                                    {Website.description || 'The WenPush API'}
                                </p>
                            </div>
                        </div>
                        <Nav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                    </div>
                    <MobileNav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                </header>

                {/* Content */}
                <div className="flex">
                    <Sidenav options={{ scrolled, sidePanelOpen }} store={store} toggles={toggles} />
                    <main className={`flex-grow ${sidePanelOpen ? 'pl-1 ml-12' : 'ml-3'} mr-3`}>
                        <Outlet />
                    </main>
                </div>

                {/* Footer */}
                <footer className="z-30 bg-gray-200 dark:bg-gray-800 py-8 sticky top-[100vh]">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            <div className="mb-4 md:mb-0">
                                <h2 className="text-md font-bold text-gray-800 dark:text-white mb-2">
                                    Made with indifference by the <a className=" text-gray-600 dark:text-gray-300 underline" rel="noreferrer" target="_blank" href="https://docs.openpu.sh/team">OpenPush team</a>
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                    &copy; 2024 OpenPush. All rights reserved.
                                </p>
                            </div>
                            <div className="flex space-x-4">
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Github className="h-6 w-6" />
                                </a>
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Twitter className="h-6 w-6" />
                                </a>
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                                    <Linkedin className="h-6 w-6" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <Toaster richColors />
        </>
    )
})

export default Layout;