import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";
import { AbortedDeferredError, NavLink } from "react-router-dom";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useLogto } from "@logto/react";
import Profile from "@/components/ui/custom/profile";

export const NavLinks = [
    { key: "home", label: "Home", path: "/" },
    { key: "docs", label: "Docs", path: "https://docs.openpu.sh/", target: "_blank", rel: "noopener noreferrer" },
    {
        key: "notifications",
        label: "Notifications",
        path: "/notifications",
        enabled: ({ Logto }) => Logto.isAuthenticated,
    },
    { key: "settings", label: "Settings", path: "/settings", enabled: ({ Logto }) => Logto.isAuthenticated },
];

export function getNavLink(key) {
    return NavLinks.find((link) => link.key === key);
}

const Nav = observer(({ options, store, toggles }) => {
    const Logto = useLogto();

    return (
        <div className="flex items-center space-x-4">
            <nav className="hidden md:block">
                <ul className="flex space-x-4">
                    {
                        NavLinks.map((link) => {
                            return ((!("enabled" in link) || link.enabled({ store, Logto })) && (
                                <li key={link.key}>
                                    <NavLink
                                        className={
                                            ({ isActive }) => {
                                                return `hover:underline text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${isActive ? "underline font-bold text-primary dark:text-primary" : ""}`
                                            }
                                        }
                                        to={link.path}
                                        onClick={() => {
                                            link.onClick?.(store)
                                        }}
                                        state={{ active: link.key }}
                                        target={link.target || "_self"}
                                        rel={link.rel || ""}
                                    >
                                        <span>
                                            {link.label}
                                        </span>
                                    </NavLink>
                                </li>
                            ) || null)
                        })
                    }
                    <li>
                        <a
                            className="hover:underline text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white cursor-pointer"
                            onClick={
                                () => {
                                    Logto.isAuthenticated ?
                                        Logto.signOut(window.location.origin)
                                        :
                                        Logto.signIn(`${window.location.origin}/callback`)
                                }
                            }
                        >
                            {Logto.isAuthenticated ? "Sign Out" : "Sign In"}
                        </a>
                    </li>
                </ul>
            </nav>
            <Button
                variant="ghost"
                size="icon"
                className="md:hidden text-gray-600 dark:text-gray-300"
                onClick={toggles.mobileMenu}
                aria-label="Toggle mobile menu"
            >
                {
                    options.mobileMenuOpen ?
                        <X className="h-5 w-5" />
                        :
                        <Menu className="h-5 w-5" />
                }
            </Button>
            {
                store.Auth?.profile && (
                    <span className="text-gray-500 dark:text-gray-300">
                        <Profile store={store} />
                    </span>
                )
            }
        </div >
    );
});

const MobileNav = observer(({ options, store, toggles }) => {
    const Logto = useLogto();

    return options.mobileMenuOpen && (
        <nav className="z-20 md:hidden bg-white dark:bg-gray-800 shadow-lg">
            <ul className="py-2" onClick={() => toggles.mobileMenu()}>
                {
                    NavLinks.map((link) => (!("enabled" in link) || link.enabled({ store, Logto })) && (
                        <li key={link.key}>
                            <NavLink
                                className={
                                    ({ isActive }) => {
                                        return `block px-2 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${isActive ? "underline font-bold text-primary dark:text-primary bg-gray-100 dark:bg-gray-700" : ""}`
                                    }
                                }
                                to={link.path}
                                onClick={() => {
                                    runInAction(() => {
                                        store.Active = link.key
                                    })
                                    link.onClick?.(store)
                                }}
                                target={link.target || "_self"}
                                rel={link.rel || ""}
                            >
                                {link.label}
                            </NavLink>
                        </li>
                    ))
                }
                <li>
                    <a
                        className="block px-2 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        onClick={
                            () => {
                                Logto.isAuthenticated ?
                                    Logto.signOut(window.location.origin)
                                    :
                                    Logto.signIn(`${window.location.origin}/callback`)
                            }
                        }                            >
                        {Logto.isAuthenticated ? "Sign Out" : "Sign In"}
                    </a>
                </li>
            </ul>
        </nav>
    )
});

export {
    Nav,
    MobileNav
}