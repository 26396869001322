import { LoremIpsum } from "lorem-ipsum";

const lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4
    },
    wordsPerSentence: {
        max: 16,
        min: 4
    }
});

export default function Component() {
    return (
        <>
            <p className="mt-3 mb-3 dark:text-gray-300">
                Powered by <a href="https://github.com/openpush" target="_blank" rel="noreferrer" className="text-primary hover:underline">OpenPush</a>.
            </p>

            <hr className="mb-3" />

            {
                [...Array(20)].map((_, index) => (
                    <p key={index} className="mb-3 dark:text-gray-300">
                        {lorem.generateWords(~~(Math.random() * 50) + 20)}
                    </p>
                ))
            }
        </>
    )
}