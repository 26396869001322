import { makeObservable, observable, action, computed, set } from "mobx";
import WordHash from "wordhash";

let wordHash = WordHash();

export class DeviceStore extends Object {
    value = {};

    constructor(devices = []) {
        super();
        makeObservable(this, {
            value: observable,
            add: action,
            remove: action,
            count: computed,
            ids: computed,
            values: computed,
            entries: computed,
            empty: computed
        }).add(devices);
    }

    // Add devices(s) to the store
    add(devices) {
        set(this.value, Object.fromEntries((Array.isArray(devices) ? devices : [devices]).map(i => {
            return (i instanceof Device ? i : new Device(i)).kv;
        })))
    }

    // Remove device(s) from the store
    remove(devices) {
        return (Array.isArray(devices) ? devices : [devices]).reduce((acc, i) => {
            let item = i instanceof Device ? i : new Device(i);
            if (this.value.hasOwnProperty(item.id)) {
                delete this.value[item.id];
                return acc
            } else {
                return [...acc, item];
            }
        }, []);
    }

    has(id) {
        return this.value.hasOwnProperty(id instanceof Device ? id.id : id)
    }

    get count() {
        return Object.keys(this.value).length
    }

    get ids() {
        return Object.keys(this.value)
    }

    get values() {
        return Object.values(this.value)
    }

    get entries() {
        return Object.entries(this.value)
    }

    get empty() {
        return Object.keys(this.value).length <= 0
    }
}

export class Device extends Object {
    value = {};

    constructor(content = {}) {
        super();
        makeObservable(this, {
            value: observable,
            id: computed,
            ua: computed,
            created_at: computed,
            kv: computed,
            name: computed,
        })
        Object.assign(this.value, {
            ...(content || {}),
            hashed: wordHash.hash(content?.id),
        });
    }

    get id() {
        return this.value.id
    }

    get ua() {
        return this.value.ua
    }

    get created_at() {
        return this.value.created_at
    }

    get name() {
        return this.value.hashed
    }

    get kv() {
        return [this.id, this]
    }
}